<template>
  <div class="details-whatto mt-5">
    <!-- what to bring -->
    <div class="item-cover px-3 py-2 mb-2 purple" v-if="item.data.bring.length > 0">
      <span class="white--text font-14 font-500"> WHAT TO BRING </span>
    </div>
    <div class="px-4 py-2">
      <div v-for="(detail) in item.data.bring" :key="detail._id">
        <span class="font-14 black-grey--text">• {{ detail.label[$root.language] }}</span>
      </div>
    </div>

    <!-- what to wear -->
    <div class="item-cover px-3 py-2 mb-2 darkblue" v-if="item.data.wear.length > 0">
      <span class="white--text font-14 font-500">WHAT TO WEAR</span>
    </div>
    <div class="px-4 py-2">
      <div v-for="(detail) in item.data.wear" :key="detail._id">
        <span class="font-14 black-grey--text">• {{ detail.label[$root.language] }}</span>
      </div>
    </div>
    <!-- what to expect -->

    <div class="item-cover px-3 py-2 mb-2 orange" v-if="item.data.expect.length > 0">
      <span class="white--text font-14 font-500">WHAT TO EXPECT</span>
    </div>
    <div class="px-4 py-2">
      <div v-for="(detail) in item.data.expect" :key="detail._id">
        <span class="font-14 black-grey--text">• {{ detail.label[$root.language] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    sections: [],
  }),
};
</script>

<style lang="scss" scoped>
.details-whatto {
  .item-cover {
    border-radius: 15px;
  }
}
</style>
