<template>
  <div class="details-text">
    <div class="b-bottom pt-4">
      <!-- text details -->
      <div class="main-title d-flex align-center justify-space-between">
        <div>
          <span
            class="main-title d-block my-2 black--text transition-3s font-20 font-500"
            >{{ item.title[$root.language] }}</span
          >
          <span
            class="main-title d-block my-2 black-grey--text transition-3s font-14 font-500"
            >{{ item.mainLocation }} -
            {{ currentDistrict(item.district) }}</span
          >
        </div>
        <div class="d-flex" v-if="!viewOnly">
          <div
            v-if="actions.includes('hide')"
            class="icon-cover mr-1 cursor-pointer base-btn"
            @click="showHide"
          >
            <v-icon v-if="item.isHidden">mdi-eye-off-outline</v-icon>
            <v-icon v-else>mdi-eye-outline</v-icon>
          </div>
          <div
            v-if="actions.includes('delete')"
            class="icon-cover mr-1 cursor-pointer base-btn"
            @click="deleteConfirm"
          >
            <v-icon color="error">mdi-delete</v-icon>
          </div>
          <div
            v-if="actions.includes('notify')"
            class="icon-cover mr-1 cursor-pointer base-btn"
            @click="newNotificationDialog = true"
          >
            <v-icon color="blue">mdi-bell-ring</v-icon>
          </div>
          <div
            v-if="actions.includes('edit')"
            class="icon-cover mr-1 cursor-pointer base-btn"
            @click="$emit('editHandler')"
          >
            <v-icon color="black-grey">mdi-pencil</v-icon>
          </div>
          <div
            v-if="actions.includes('restore')"
            class="icon-cover mr-1 cursor-pointer base-btn"
            @click="$emit('restoreHandler', item)"
          >
            <v-icon color="green">mdi-file-undo</v-icon>
          </div>
        </div>
      </div>
      <p class="black--text font-15 mt-3">
        {{ item.header[$root.language] }}
      </p>
      <p class="gray--text font-14">
        {{ item.body[$root.language] }}
      </p>
    </div>
    <v-dialog v-model="newNotificationDialog" width="750px">
      <new-notification
        :key="newNotificationDialog"
        @close="newNotificationDialog = false"
        :activity="item"
      ></new-notification>
    </v-dialog>
    <v-dialog v-model="deleteDialog" width="300">
      <delete-confirm
        :key="deleteDialog"
        @confirmAccess="deleteHandler"
        @closeDialog="deleteDialog = false"
      ></delete-confirm>
    </v-dialog>
  </div>
</template>

<script>
import NewNotification from "@/components/dialogs/NewNotification.vue";
export default {
  components: {
    NewNotification
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    districts: {
      type: Array,
      default: () => []
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => ["hide", "delete", "notify", "edit"]
    }
  },
  data: () => ({
    deleteDialog: false,
    experienceDelete: { Experiences: "delete" },
    experienceEdit: { Experiences: "edit" },
    deleteEnable: false,
    editEnable: false,
    newNotificationDialog: false
  }),
  methods: {
    deleteConfirm() {
      this.deleteDialog = true;
    },
    currentDistrict(val) {
      return this.districts.filter((dis) => dis._id == val)[0]?.name;
    },
    async deleteHandler() {
      const res = await this.$axios.delete(`/activity/${this.item._id}`);

      if (res && res.data) {
        this.$store.dispatch("showSnack", {
          text: "deleted successfully",
          color: "success"
        });
        this.deleteDialog = false;
        this.$emit("reloadData");
        this.$emit("collapse");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.error,
          color: "error"
        });
      }
    },
    async showHide() {
      if (this.item._id) {
        let resEdit = await this.$axios.put(`host/activity/${this.item._id}`, {
          isHidden: !this.item.isHidden
        });
        if (resEdit.data) {
          this.$emit("reloadData");
        }
      }
    }
  },
  async created() {
    this.deleteEnable = await this.$store.dispatch(
      "checkPermission",
      this.experienceDelete
    );
    this.editEnable = await this.$store.dispatch(
      "checkPermission",
      this.experienceEdit
    );
  }
};
</script>

<style></style>
