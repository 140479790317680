<template>
  <div class="details-steps">
    <v-tabs v-model="selectedDay" centered class="mb-5">
      <v-tabs-slider color="secondary"></v-tabs-slider>

      <v-tab
        v-for="(day, i) in item.data.newSchedules || []"
        :key="i"
        @click="selectDayHandler(day)"
      >
        Day {{ i + 1 }}
      </v-tab>
    </v-tabs>
    <div class="mb-5">
      <GmapMap
        v-if="
          item.data &&
          item.data.newSchedules &&
          item.data.newSchedules.length > 0 &&
          item.data.newSchedules[selectedDay] &&
          item.data.newSchedules[selectedDay].day &&
          item.data.newSchedules[selectedDay].day.schedule &&
          item.data.newSchedules[selectedDay].day.schedule.length > 0 &&
          item.data.newSchedules[selectedDay].day.schedule[0] &&
          item.data.newSchedules[selectedDay].day.schedule.some(
            (item) => item.latitude
          ) &&
          item.data.newSchedules[selectedDay].day.schedule.some((item) => item.longitude)
        "
        :center="{
          lat: Number(
            item.data.newSchedules[selectedDay].day.schedule.find((item) => item.latitude)
              ?.latitude
          ),
          lng: Number(
            item.data.newSchedules[selectedDay].day.schedule.find(
              (item) => item.longitude
            )?.longitude
          )
        }"
        :zoom="10"
        style="width: 100%; height: 300px"
      >
        <GmapMarker
          :key="index"
          v-for="(marker, index) in markers || []"
          :position="{
            lat: Number(marker.latitude),
            lng: Number(marker.longitude)
          }"
        />
      </GmapMap>
      <div
        v-else
        style="
          width: 100%;
          height: 300px;
          border: 1px solid gray;
          border-radius: 5px;
        "
      >
        <p class="font-16 text-center red--text">Markers are missing</p>
      </div>
    </div>

    <div class="steps">
      <div class="step-line"></div>
      <div class="mb-8">
        <div v-for="(schedule, j) in selectedDayObject.schedule" :key="j">
          <div class="w-100">
            <div class="d-flex align-center justify-space-between">
              <div class="d-flex align-center justify-space-between">
                <div class="step-min b-cover px-2 py-1 radius-20 mr-2">
                  <v-icon color="secondary"
                    >mdi-{{ transportIcon(schedule.transport) }}</v-icon
                  >
                </div>
                <span class="secondary--text font-12 font-500">{{
                  schedule.location
                }}</span>
              </div>
            </div>

            <div class="pl-7 mt-2">
              <span class="font-20 font-500 d-block black--text">{{
                schedule.title[$root.language]
              }}</span>
              <p class="font-12 white-grey--text">
                {{ schedule.description[$root.language] }}
              </p>
            </div>
          </div>

          <div class="photos-section">
            <div class="photo mb-2 ml-5" style="height: 120px; width: 100px">
              <div
                v-if="schedule.image[0]"
                class="photo-count cursor-pointer"
                @click="photosDialogHandler(schedule.image)"
              >
                <span
                  v-if="schedule.image.length > 1"
                  class="white--text font-26"
                  >+{{ schedule.image.length }}</span
                >
              </div>
              <img
                v-if="schedule.image[0] && schedule.image[0].mediaId"
                :src="schedule.image[0].mediaId.url"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog
      v-model="photosDialog"
      fullscreen
      hide-overlay
      transition="scale-transition"
      content-class="full-dialog"
    >
      <dialog-photos
        :key="photosDialog"
        :images="images"
        type="experience"
        @close="photosDialog = false"
      ></dialog-photos>
    </v-dialog>
  </div>
</template>

<script>
import DialogPhotos from "@/components/dialogs/Photos";

export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    DialogPhotos
  },
  data: () => ({
    photosDialog: false,
    selectedDay: "",
    images: [],
    selectedDayObject: {}
  }),
  computed: {
    markers() {
      let arr = [];
      this.item.data.newSchedules.forEach((day) => {
        day.day.schedule.forEach((sh) => {
          arr.push(sh);
        });
      });
      return arr;
    }
  },
  methods: {
    photosDialogHandler(images) {
      this.photosDialog = true;
      this.images = images;
    },
    selectDayHandler(day) {
      this.selectedDayObject = day.day;
    },
    transportIcon(transport) {
      if (transport == "drive") {
        return "car";
      }
      if (transport == "walk") {
        return "walk";
      }
      if (transport == "swim") {
        return "swim";
      }
      if (transport == "eat") {
        return "walk";
      } else {
        return "";
      }
    }
  },
  created() {
    if (
      this.item.data &&
      this.item.data.newSchedules &&
      this.item.data.newSchedules.length > 0
    ) {
      this.selectedDayObject = this.item.data.newSchedules[0].day;
    }
  }
};
</script>

<style lang="scss">
.details-steps {
  .steps {
    position: relative;

    .step-line {
      content: "";
      position: absolute;
      left: 15px;
      top: 10px;
      height: calc(100% - 130px);
      width: 2px;
      background: $grayicon;
    }

    .step-min {
      position: relative;
      z-index: 9;
      background: white;
    }
  }
}
</style>
