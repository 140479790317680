<template>
  <div class="details-needtoknow pb-5">
    <span class="d-block black-grey--text font-500 font-15 mb-4"
      >NEED TO KNOW</span
    >
    <div
      v-for="item in item.stats || []"
      :key="item._id"
      class="d-flex align-center justify-space-between mb-2"
      :class="item.active ? 'secondary--text' : ''"
    >
      <div class="d-flex align-center">
        <!-- <img class="mr-2" width="20" :src="item.icon" alt="" /> -->
        <span class="white-grey--text font-14">{{ item.label }}</span>
      </div>
      <span class="font-14 black--text">{{ item.value }}</span>
    </div>

    <div
      class="d-flex align-center justify-space-between mb-2 mt-5"
      v-if="
        item.data &&
        item.data.newPricing &&
        item.data.newPricing.startPriceInUsd
      "
    >
      <div class="d-flex align-center">
        <span class="white-grey--text font-15 font-500">PRICE</span>
      </div>
      <span class="font-22 black--text"
        >$
        {{ item.data.newPricing.startPriceInUsd.toLocaleString("en") }}
      </span>
    </div>

    <template v-for="option in item.data.options || []">
      <div :key="option._id" v-if="option.included">
        <span class="white-grey--text font-14 d-block">{{
          option.description[$root.language]
        }}</span>
      </div>
    </template>

    <div class="mt-4">
      <span class="white-grey--text font-15 font-500">EXTRAS</span>
      <template v-for="option in item.data.options || []">
        <div
          v-if="!option.included"
          class="extra-cover d-flex align-center justify-space-between lightblue px-3 py-2 mb-2"
          :key="option._id"
        >
          <span class="white--text font-14 font-500 d-block">{{
            option.description[$root.language]
          }}</span>
          <span class="white--text font-14 font-500 d-block"
            ><b>+ $ {{ option.price }}</b></span
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    items: [
      {
        title: "Category",
        value: "Hiking",
        active: true,
        icon: require("@/assets/images/icons/category.png")
      },
      {
        title: "Difficulty Level",
        value: "Hard",
        active: false,
        icon: require("@/assets/images/icons/level.png")
      },
      {
        title: "Total Distance",
        value: "120Km",
        active: false,
        icon: require("@/assets/images/icons/distance.png")
      },
      {
        title: "Max Number of People",
        value: "12",
        active: false,
        icon: require("@/assets/images/icons/people.png")
      },
      {
        title: "Number of Restaurants",
        value: "6",
        active: false,
        icon: require("@/assets/images/icons/restaurant.png")
      }
    ]
  })
};
</script>

<style lang="scss" scoped>
.details-needtoknow {
  .extra-cover {
    border-radius: 20px;
    max-width: 180px;
  }
}
</style>
